import * as React from 'react';
import { Helmet } from 'react-helmet';
import Div100vh from 'react-div-100vh';
import SEO, { ISEOProps } from './SEO';
import { ClassHelpers } from '@rocketmakers/armstrong';
import Navigation from './navigation';

import './layout.scss';
import Footer from './footer';
import { GlobalContext } from '../context/globalContext';

interface IProps {
  className?: string;
  pageMeta: ISEOProps;
  onehundredvh?: boolean;
}

const Layout: React.FC<IProps> = ({ children, pageMeta, className, onehundredvh }) => {
  return (
    <>
      <Helmet bodyAttributes={{ class: onehundredvh ? 'body-no-scroll' : '' }} />
      <SEO {...pageMeta} />
      {onehundredvh ? (
        <Div100vh id="site" className={ClassHelpers.classNames('site-container', 'fixed-height', className)}>
          <Navigation />
          <main>{children}</main>
        </Div100vh>
      ) : (
        <div id="site" className={ClassHelpers.classNames('site-container', className)}>
          <Navigation />
          <main>{children}</main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Layout;
