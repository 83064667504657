import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export interface ISEOProps {
  description: string;
  image?: {
    src: string;
    height: string;
    width: string;
  };
  keywords: string;
  title: string;
}

const SEO: React.FC<ISEOProps> = ({ description, keywords, title, image }) => {
  // const wow = useSiteMetadata();
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(
        image && image.src
          ? [
              {
                property: 'og:image',
                content: `${image.src}`,
              },
              {
                property: 'og:image:width',
                content: image.width,
              },
              {
                property: 'og:image:height',
                content: image.height,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
            ]
          : [
              {
                name: 'twitter:card',
                content: 'summary',
              },
            ]
      )}
    />
  );
};

export default SEO;
