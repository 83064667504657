import * as React from 'react';
import { Link } from 'gatsby';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import './navigation.scss';
import { GlobalContext } from '../context/globalContext';
import { MobileMenuButton } from './mobileMenuButton';
import { IconWrapper } from './iconWrapper';

const Navigation: React.FC = () => {
  const [up, setUp] = React.useState(false);
  const [top, setTop] = React.useState(true);
  const scrollPos = React.useRef(typeof window !== 'undefined' && window.pageYOffset);

  const { isMobile } = React.useContext(GlobalContext);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setTop(window.pageYOffset <= 0 ? true : false);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = React.useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    const visible = scrollPos.current > currentScrollPos;

    scrollPos.current = currentScrollPos;
    setTop(currentScrollPos <= 0 ? true : false);
    setUp(!visible);
  }, []);

  const navItems = React.useMemo(
    () => [
      { name: 'overview', to: '/', transitionColor: '#656A71', partiallyActive: false },
      { name: 'projects', to: '/projects', transitionColor: '#656A71', partiallyActive: true },
      { name: 'about', to: '/about', transitionColor: '#656A71', partiallyActive: true },
      { name: 'contact', to: '/contact', transitionColor: '#656A71', partiallyActive: true },
    ],
    []
  );

  return (
    <>
      <header data-up={up} data-top={top} data-mobile-menu-open={open}>
        <AniLink to={'/'} className="name" cover direction="down" bg={'#656A71'}>
          Evangeline Harbury
        </AniLink>
        <AniLink to={'/'} className="name small">
          EH
        </AniLink>
        {isMobile ? (
          <MobileMenuButton onClick={setOpen} open={open} />
        ) : (
          <div className="nav-links">
            {navItems.map((item, i) => (
              <AniLink
                key={i}
                to={item.to}
                activeClassName="active"
                cover
                direction="down"
                partiallyActive={item.partiallyActive}
                bg={item.transitionColor}
              >
                <IconWrapper random="both">
                  <span title={item.name}>{item.name}</span>
                </IconWrapper>
              </AniLink>
            ))}
          </div>
        )}
      </header>
      {open && (
        <div className="mobile-menu">
          <div className="links">
            {navItems.map((item, i) => (
              <AniLink
                key={i}
                to={item.to}
                activeClassName="active"
                cover
                direction="down"
                partiallyActive={item.partiallyActive}
                bg={item.transitionColor}
              >
                <span>{item.name}</span>
              </AniLink>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
