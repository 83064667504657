import * as React from 'react';
import './iconWrapper.scss';
import * as _ from 'underscore';

interface IProps {
  icon1?: FoodIcons;
  icon2?: FoodIcons;
  random?: 'icon1' | 'icon2' | 'both';
}
export const IconWrapper: React.FC<IProps> = ({ children, icon1, icon2, random }) => {
  const getRandomIcon = () => {
    return foodIconArray[_.random(foodIconArray.length - 1)];
  };

  const randomIcon1 = React.useMemo(() => {
    return getRandomIcon();
  }, []);
  const randomIcon2 = React.useMemo(() => {
    return getRandomIcon();
  }, []);

  return (
    <div className="icon-wrapper">
      {(icon1 || random === 'icon1' || random === 'both') && (
        <img className="icon1" src={require(`../img/icons/${icon1 || randomIcon1}.svg`)} />
      )}
      {children}
      {(icon2 || random === 'icon2' || random === 'both') && (
        <img className="icon2" src={require(`../img/icons/${icon2 || randomIcon2}.svg`)} />
      )}
    </div>
  );
};

export type FoodIcons =
  | 'apple'
  | 'artichoke'
  | 'avocado'
  | 'banana'
  | 'blueberries'
  | 'broccoli'
  | 'carrot'
  | 'cherry'
  | 'chili-pepper'
  | 'cleaver'
  | 'coffee-grinder'
  | 'corn-1'
  | 'corn'
  | 'cutlery'
  | 'dill'
  | 'dinner'
  | 'eggplant'
  | 'fork'
  | 'garlic'
  | 'grape-1'
  | 'grape'
  | 'juicer'
  | 'kitchen-board'
  | 'knife-1'
  | 'knife'
  | 'ladle'
  | 'leek'
  | 'lemon'
  | 'mixer'
  | 'mushroom'
  | 'olives'
  | 'onion'
  | 'orange'
  | 'paprika'
  | 'peach'
  | 'pear'
  | 'pepper'
  | 'pineapple'
  | 'pomegranate'
  | 'potato'
  | 'pumpkin'
  | 'rolling-pin'
  | 'salt'
  | 'sauce'
  | 'spatula'
  | 'spoon'
  | 'strawberry'
  | 'tomato'
  | 'turnip'
  | 'watermelon';

const foodIconArray: FoodIcons[] = [
  'apple',
  'artichoke',
  'avocado',
  'banana',
  'blueberries',
  'broccoli',
  'carrot',
  'cherry',
  'chili-pepper',
  'cleaver',
  'coffee-grinder',
  'corn-1',
  'corn',
  'cutlery',
  'dill',
  'dinner',
  'eggplant',
  'fork',
  'garlic',
  'grape-1',
  'grape',
  'juicer',
  'kitchen-board',
  'knife-1',
  'knife',
  'ladle',
  'leek',
  'lemon',
  'mixer',
  'mushroom',
  'olives',
  'onion',
  'orange',
  'paprika',
  'peach',
  'pear',
  'pepper',
  'pineapple',
  'pomegranate',
  'potato',
  'pumpkin',
  'rolling-pin',
  'salt',
  'sauce',
  'spatula',
  'spoon',
  'strawberry',
  'tomato',
  'turnip',
  'watermelon',
];
