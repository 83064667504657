import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './footer.scss';
const Footer: React.FC = () => {
  return (
    <footer>
      <StaticQuery
        query={graphql`
          query Socials {
            prismic {
              allSocialss {
                edges {
                  node {
                    socials {
                      type
                      link {
                        ... on PRISMIC__ExternalLink {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      >
        {(data) => (
          <div className="socials">
            {data.prismic.allSocialss.edges[0].node.socials.map((social) => (
              <a href={social.link.url} target="_blank">
                <img src={require(`../img/socials/${social.type}.svg`)} />
              </a>
            ))}
          </div>
        )}
      </StaticQuery>
    </footer>
  );
};

export default Footer;
