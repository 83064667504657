import * as React from 'react';
import './mobileMenuButton.scss';
export const MobileMenuButton: React.FC<{ open: boolean; onClick: (open: boolean) => void }> = ({ open, onClick }) => {
  return (
    <div className="menu-btn" data-open={open} onClick={() => onClick(!open)}>
      <div />
      <div />
      <div />
    </div>
  );
};
